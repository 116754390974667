import * as React from "react"
import { Link } from "gatsby"

const BlogHeader = () => (
    <div className="container mx-auto mt-5 mb-20 my-15">
        <h1>
            <Link to="/blog">Logickal Blog</Link></h1>
        <h5>Musings on musick, technology and finite measurement of time</h5>
    </div>
)

export default BlogHeader