import React from "react"

const BlogTags = ({ tags }) => {
    if (tags.length === 0) {
        return null
    }
    const tagString = tags.join(", ")
    return (
        <div className="inline">{tagString}</div>
    )
}

export default BlogTags