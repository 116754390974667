import * as React from "react"
import { Link, graphql } from "gatsby"

import Seo from "../../components/seo"
import BlogHeader from "../../components/blogheader"
import BlogTags from "../../components/blogtags"

const BlogPage = ({ data }) => (
    <div>
        <Seo title="Blog" />
        <BlogHeader/>
        <div className="container mx-auto">

            <ul className="list-disc">
                {
                    data.allFile.nodes.map((node) => (
                        <article
                            className="article m-10 p-5"
                            key = {node.id}>
                            <h3>
                              <Link to={`/blog/${node.childMdx.slug}`} >
                              {node.childMdx.frontmatter.title}
                              </Link>
                            </h3>
                            <p className="italic">Posted: {node.childMdx.frontmatter.date} in <BlogTags tags={node.childMdx.frontmatter.tags} />
                            </p>
                            <div className="body">
                                    {node.childMdx.excerpt}
                            </div>
                        </article>
                    ) )
                }
            </ul>
        </div>

    </div>
    
)

export const query = graphql`
query BlogQuery {
    allFile(
      filter: {sourceInstanceName: {eq: "posts"}}
      sort: {fields: childMdx___frontmatter___date, order: DESC}
    ) {
      nodes {
        childMdx {
          id
          body
          slug
          excerpt
          frontmatter {
            title
            date
            tags
          }
        }
      }
    }
  }
`

export default BlogPage
